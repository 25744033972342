<template>
  <el-dialog
    :before-close="handleClose"
    :visible.sync="show"
    append-to-body
    v-dialogDrag
    width="90%"
    :close-on-click-modal="false"
    center
  >
    <div slot="title" style="padding: 15px; font-weight: bold">视频素材</div>
    <div class="container">
      <div>
        <div class="choose-type">
          <el-button type="info" disabled>视频</el-button>
          <el-button disabled>图片</el-button>
          <span
            class="margin-left-ten"
            style="color: #fd5d5a; font-weight: bold"
            >视频不支持多个同时上传</span
          >
        </div>
        <template v-if="!isEdit">
          <div class="tips">
            <span>上传视频</span>
            <span>视频格式：大小<=100M, 时长<= 1小时</span>
            <el-progress
              style="width: 365px; margin-top: 10px"
              :percentage="Math.floor($store.state.uploadAudioPercent)"
            ></el-progress>
          </div>
          <div
            class="upload-files"
            ref="uploadDom"
            @drop.prevent="onDrop"
            @dragover.prevent="onDragover"
            @dragleave.prevent="onDragLeave"
            @click="handleUploadFile"
          >
            <div>
              <p>
                <i
                  class="iconfont icon-xinzeng"
                  style="font-size: 40px; font-weight: bold"
                >
                </i>
              </p>
              <p>点击或拖拽上传</p>
              <p>支持MP4、AVI、MOV格式视频、大小不超过100M</p>
            </div>
            <input
              type="file"
              ref="uploadFile"
              style="display: none"
              accept="video/*"
              @change="handleFileChange"
            />
          </div>
        </template>
        <div class="files-info" v-if="dataList.length > 0">
          <c-table
            :border="true"
            :dataList="dataList"
            :rowList="rowList"
            :isShowPagination="false"
            operationWidth="200"
            height="40vh"
          >
            <template #tagItemIds="{ row }">
              <template v-if="!row.tagItemIds || row.tagItemIds.length === 0">
                <el-button
                  icon="el-icon-plus"
                  @click="addTag(row)"
                  type="primary"
                  class="button-small"
                  size="small"
                  >新增标签</el-button
                >
              </template>
              <template v-else>
                <div class="flex-row-start-start">
                  <div class="tags-list">
                    <div
                      class="tag-item"
                      v-for="(item, index) in row.tagItemIds"
                      :key="index"
                    >
                      <template
                        v-if="
                          Array.isArray(item.checkedTagList) &&
                          item.checkedTagList.length > 0
                        "
                      >
                        <p>{{ item.groupName }}</p>
                        <div class="flex-row-start-start-wrap">
                          <div
                            class="tag-list"
                            v-for="tag in item.checkedTagList"
                            :key="tag.id"
                          >
                            <el-tag type="info" size="mini">
                              {{ tag.name }}
                            </el-tag>
                            <i
                              class="iconfont icon-shanchu1 del-item"
                              @click.stop="
                                ($event) => {
                                  delItem(row, tag, index);
                                }
                              "
                            ></i>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <i
                    class="el-icon-circle-plus add-icon"
                    style="color: #00bf85; cursor: pointer"
                    @click="
                      ($event) => {
                        addTag(row);
                      }
                    "
                  ></i>
                </div>
              </template>
            </template>
            <template #title="{ row }">
              <div class="title">
                <span v-if="!isEditTitle">{{ row.title }}</span>
                <el-input v-else v-model="row.title" placeholder="请输入标题">
                  <el-button
                    slot="append"
                    icon="el-icon-circle-check"
                    @click="changeTitle"
                  ></el-button>
                </el-input>
                <i
                  style="margin-left: 10px; font-size: 18px; color: #00bf8a"
                  class="el-icon-edit"
                  v-if="!isEditTitle"
                  @click="isEditTitle = true"
                ></i>
              </div>
            </template>

            <template #documentWareIds="{ row }">
              <div class="doc-list">
                <template
                  v-if="
                    !Array.isArray(row.documentWareIds) ||
                    row.documentWareIds.length === 0
                  "
                >
                  <el-button
                    type="primary"
                    class="button-small"
                    size="small"
                    @click="addDoc(row)"
                    icon="el-icon-plus"
                    >添加文案</el-button
                  >
                </template>
                <template v-else>
                  <div
                    v-for="(doc, index) in row.documentWareIds"
                    :key="index"
                    class="doc-item"
                  >
                    <p>{{ doc.content }}</p>

                    <span style="margin-left: 5px">
                      <i
                        class="el-icon-remove"
                        style="color: #ccc; margin-right: 5px"
                        @click="
                          ($event) => {
                            removeDoc(row, index);
                          }
                        "
                      ></i>
                      <i
                        class="el-icon-circle-plus"
                        v-if="index + 1 === row.documentWareIds.length"
                        style="color: #00bf8a"
                        @click="
                          ($event) => {
                            addDoc(row);
                          }
                        "
                      ></i>
                    </span>
                  </div>
                </template>
              </div>
            </template>

            <template #buttonOperation="{ row }">
              <el-button
                type="primary"
                @click="previewItem(row)"
                size="small"
                class="button-small"
                >预览</el-button
              >
              <el-button
                type="danger"
                @click="delRow(row)"
                size="small"
                class="button-small"
                >删除</el-button
              >
            </template>
          </c-table>
        </div>
      </div>
      <div class="button-list">
        <el-button type="primary" @click="Ok">确定</el-button>
        <el-button @click="NotOk">取消</el-button>
      </div>
    </div>
    <add-tag
      :show="showAddTagDialog"
      :info="tagsList"
      :curInfo="curInfo"
      @close="closeAddDialog"
      @success="chooseTagSuccess"
    ></add-tag>
    <add-doc
      :show="showAddDocDialog"
      :curInfo="curInfo"
      @close="choseDocDialog"
      @success="chooseDocSuccess"
    ></add-doc>
    <preview-item
      :show="showPreviewDialog"
      :curInfo="formatData"
      @close="showPreviewDialog = false"
    >
    </preview-item>
  </el-dialog>
</template>

<script>
import PreviewItem from "./preview.vue";
import AddTag from "./add-tag.vue";
import AddDoc from "./add-doc.vue";

import { uploadFile } from "@/api/common.js";
import { formatTime, _changeTimeBySecond } from "@/assets/js/utils";
import {
  addMaterialWare,
  getTagGroupList,
  editMaterialWare,
} from "@/api/material";
import { mapMutations } from "vuex";
const VIDEO_TYPE = ["video/mp4", "video/avi", "video/quicktime"];
const LIMIT_DURATION = 3600;
const TOTAL_KINDS = 5; // ! 一共个标签组
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    echoData: {
      type: Object,
    },
  },
  data() {
    return {
      docList: [],
      tagsList: [],
      curInfo: {},
      dragover: false,
      isEditTitle: false,
      showAddTagDialog: false,
      isEdit: false,
      title: "",
      idSum: 1,
      dataList: [],
      formatData: {},
      showAddDocDialog: false,
      showPreviewDialog: false,
      rowList: [
        {
          label: "视频标题",
          prop: "title",
          slot: true,
          width: "150",
        },
        {
          label: "视频尺寸",
          prop: "widthAndHigh",
          width: "80",
        },
        {
          label: "视频时长",
          prop: "videoTime",
          width: "80",
        },
        {
          label: "视频格式",
          prop: "format",
          width: "80",
        },
        {
          label: "视频大小",
          prop: "size",
          width: "80",
        },
        {
          label: "标签",
          prop: "tagItemIds",
          slot: true,
          width: 200,
        },
        {
          label: "文案",
          prop: "documentWareIds",
          slot: true,
          width: "250",
        },
      ],
      percent: 0, // 上传进度
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.setUploadAudioPercent(0);
          if (this.echoData) {
            this.rederEchoData();
          }
        }
      },
    },
  },
  methods: {
    rederEchoData() {
      getTagGroupList({
        pageSize: 999,
      }).then((res) => {
        const list = res.list;
        const alreadyChooseTags = [];
        this.echoData.tagItems.forEach((tag) => {
          const targetIndex = alreadyChooseTags.findIndex(
            (item) => item.groupId === tag.tagGroupId
          );
          if (targetIndex === -1) {
            alreadyChooseTags.push({
              groupId: tag.tagGroupId,
              groupName: list.find((item) => item.id === tag.tagGroupId)
                .tagGroupName,
              checkedTagList: [tag],
            });
          } else {
            alreadyChooseTags[targetIndex] = {
              ...alreadyChooseTags[targetIndex],
              checkedTagList: [
                ...alreadyChooseTags[targetIndex].checkedTagList,
                tag,
              ],
            };
          }
        });
        const val = this.echoData.resources[0];
        this.isEdit = true;
        this.dataList.push({
          url: val.url,
          format: val.format,
          size: val.size,
          sort: val.sort,
          title: val.title,
          videoTime: val.videoTime,
          widthAndHigh: val.widthAndHigh,
          id: val.id,
          documentWareIds: this.echoData.documentWares,
          // tagItemIds: this.echoData.tagItems
          tagItemIds: alreadyChooseTags,
        });
      });
    },
    previewItem(row) {
      this.formatData = {
        adminUserName: this.isEdit
          ? this.echoData.adminUserName
          : this.$store.state.userInfo.name,
        createdTime: formatTime(new Date()),
        documentCount: row.documentWareIds ? row.documentWareIds.length : 0,
        documentWares: row.documentWareIds ? row.documentWareIds : [],
        tagItems: row.tagItemIds ? row.tagItemIds : [],
        imgCount: 0,
        materialType: 1,
        resources: [
          {
            format: row.format,
            size: row.size,
            title: row.title,
            url: row.url,
            videoTime: row.videoTime,
            widthAndHigh: row.widthAndHigh,
          },
        ],
      };
      this.showPreviewDialog = true;
    },
    delRow(row) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const id = row.id;
          const targetIndex = this.dataList.findIndex((item) => item.id === id);
          this.dataList.splice(targetIndex, 1);
          // !重置百分比
          this.setUploadAudioPercent(0);
        })
        .catch(() => {});
    },
    onDrop(e) {
      const targetFile = e.dataTransfer.files[0];
      this.checkVideoStats(targetFile);
    },
    handleUploadFile() {
      this.$refs.uploadFile.click();
    },
    onDragover(e) {
      this.dragover = true;
      this.$refs.uploadDom.style.borderColor = "#00bf8a";
    },
    onDragLeave(e) {
      this.dragover = false;
    },
    handleFileChange(e) {
      const targetFile = e.target.files[0];
      this.checkVideoStats(targetFile);
    },
    changeTitle() {
      this.isEditTitle = false;
    },
    addTag(row) {
      getTagGroupList({
        pageSize: 999,
        status: 1,
      }).then((res) => {
        this.tagsList = res.list;
        this.showAddTagDialog = true;
        this.curInfo = JSON.parse(JSON.stringify(row));
      });
    },
    addDoc(row) {
      this.curInfo = JSON.parse(JSON.stringify(row));
      this.showAddDocDialog = true;
    },
    delItem(row, tag, index) {
      const { tagItemIds } = JSON.parse(JSON.stringify(row));
      const { tagGroupId, id } = tag;
      const targetIndex = tagItemIds.findIndex(
        (item) => item.groupId === tagGroupId
      );
      const subIndex = tagItemIds[targetIndex].checkedTagList.findIndex(
        (item) => item.id === id
      );
      const Index = this.dataList.findIndex((item) => item.id === row.id);
      tagItemIds[targetIndex].checkedTagList.splice(subIndex, 1);
      this.$set(this.dataList, Index, {
        ...row,
        tagItemIds,
      });
    },
    checkVideoStats(targetFile) {
      const { type, size } = targetFile;
      if (!VIDEO_TYPE.includes(type)) {
        this.$message.error("上传的视频格式不符合要求!");
        return;
      }
      // 临时video标签元素
      const temVideoDom = document.createElement("video");
      const url = URL.createObjectURL(targetFile);
      temVideoDom.src = url;
      document.body.append(temVideoDom);
      temVideoDom.style = "display:absolute;left:-9999px";
      // const styleObj = window.getComputedStyle(temVideoDom)
      // const { height, width } = styleObj

      temVideoDom.oncanplay = (e) => {
        const { duration, videoHeight, videoWidth } = temVideoDom;
        if (duration > LIMIT_DURATION) {
          document.body.removeChild(temVideoDom);
          return this.$message.error("请上传一个小时以内长度的视频！");
        }
        if (size >= 1024 * 1024 * 100) {
          document.body.removeChild(temVideoDom);
          this.$message.error("请上传小于100M的视频");
        } else {
          const obj = {};
          document.body.removeChild(temVideoDom);
          this.setUploadAudioPercent(0);
          uploadFile({
            file: targetFile,
          })
            .then((res) => {
              const splitArr = targetFile.name.split(/\.+/g);
              const len = splitArr.length;
              let customName = "";
              splitArr.forEach((item, index) => {
                if (index < len - 1) {
                  customName += item;
                }
              });

              let videoSize;
              if (size / 1024 / 1024 < 1) {
                videoSize = `${Math.floor(size / 1024)}KB`;
              } else {
                videoSize = `${Math.floor(size / 1024 / 1024)}MB`;
              }
              obj.url = res;
              obj.title = customName;
              obj.widthAndHigh = `${parseInt(videoWidth)} * ${parseInt(
                videoHeight
              )}`;
              obj.videoTime = _changeTimeBySecond(Number(duration));
              obj.size = videoSize;
              obj.format = type;
              obj.id = this.idSum;
              this.idSum++;
              this.dataList.push(obj);
            })
            .finally(() => {
              this.$refs.uploadFile.value = "";
            });
        }
      };
    },
    Ok() {
      if (this.dataList.length === 0) {
        return this.$message.warning("请上传视频!");
      }
      const arr = this.dataList.map((item) => {
        return this.takeParams(item, "add");
      });
      if (!this.isEdit) {
        addMaterialWare(arr).then(() => {
          this.$message.success("新增成功!");
          this.$emit("refreshData");
          this.handleClose();
        });
      } else {
        const params = this.dataList.map((item) => {
          return this.takeParams(item, "edit");
        });
        editMaterialWare(this.echoData.id, params[0]).then(() => {
          this.$message.success("编辑成功!");
          this.handleClose();
          this.$emit("refreshData", {
            materialType: 1,
            id: this.echoData.id,
          });
        });
      }
    },
    NotOk() {
      this.dataList = [];
      this.isEdit = false;
      this.isEditTitle = false;
      this.$emit("close");
    },
    handleClose() {
      this.dataList = [];
      this.isEdit = false;
      this.isEditTitle = false;
      this.$emit("close");
    },
    closeAddDialog() {
      this.showAddTagDialog = false;
    },

    chooseTagSuccess(val) {
      this.curInfo.tagItemIds = val;
      const target = this.dataList.find((item) => item.id === this.curInfo.id);
      const targetIndex = this.dataList.findIndex(
        (item) => item.id === this.curInfo.id
      );
      target.tagItemIds = val;
      this.$set(this.dataList, targetIndex, target);
    },
    chooseDocSuccess(val) {
      const target = this.dataList.find((item) => item.id === this.curInfo.id);
      const targetIndex = this.dataList.findIndex(
        (item) => item.id === this.curInfo.id
      );
      target.documentWareIds = JSON.parse(JSON.stringify(val));
      this.$set(this.dataList, targetIndex, target);
    },
    removeDoc(row, index) {
      const data = JSON.parse(JSON.stringify(row));

      data.documentWareIds.splice(index, 1);
      const targetIndex = this.dataList.findIndex(
        (item) => item.id === data.id
      );
      this.$set(this.dataList, targetIndex, data);
    },
    choseDocDialog() {
      this.showAddDocDialog = false;
    },
    takeParams(val, type) {
      const obj = {
        materialType: 1,
        title: val.title,
        documentWareIds: val.documentWareIds
          ? val.documentWareIds.map((item) => item.id)
          : null,
      };
      const mappingIds = {
        2: {
          en: "booksTypeIds",
          cn: "书籍类型",
        },
        3: {
          en: "videoTypeIds",
          cn: "视频类型",
        },
        4: {
          en: "imageTypeIds",
          cn: "图片类型",
        },
        5: {
          en: "putChannelIds",
          cn: "投放渠道",
        },
        6: {
          en: "booksNameIds",
          cn: "书籍名称",
        },
      };

      if (val.tagItemIds !== undefined) {
        val.tagItemIds.forEach((item, index) => {
          const { groupId, checkedTagList } = item;
          const propertyName = mappingIds[groupId].en;
          if (checkedTagList.length > 0) {
            obj[propertyName] = checkedTagList.map((item) => item.id);
          }
        });
      }
      if (type === "add") {
        obj.materialResources = [
          {
            title: val.title,
            widthAndHigh: val.widthAndHigh,
            format: val.format,
            size: val.size,
            url: val.url,
            videoTime: val.videoTime,
            sort: 1,
          },
        ];
      }
      if (type === "edit") {
        obj.materialResources = [
          {
            id: val.id,
            title: val.title,
          },
        ];
      }
      return obj;
    },
    ...mapMutations(["setUploadAudioPercent"]),
  },
  components: { PreviewItem, AddTag, AddDoc },
};
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  padding-top: 20px;
  font-weight: bold;
}
.container {
  > div {
    .choose-type {
    }
    .tips {
      margin-top: 44px;
      font-weight: bold;
      > span:nth-of-type(1) {
        color: #000;
        margin-right: 37px;
      }
      > span:nth-of-type(2) {
        color: #707070;
      }
    }
    .upload-files {
      width: 100%;
      margin-top: 19px;
      border: 2px dashed #b7b7b7;
      height: 191px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        border-color: #00bf8a;
      }
      > div {
        p {
          text-align: center;
          margin-bottom: 9px;
          font-weight: bold;
        }
      }
    }
  }
}
.tags-list {
  padding-top: 10px;
  position: relative;
  min-height: 30px;
  .tag-item {
    margin: 0 10px 5px 0;
    position: relative;
    cursor: default;
    .tag-list {
      position: relative;
      display: inline-block;
      margin: 0 5px 5px 0;
      .del-item {
        position: absolute;
        top: -30%;
        left: 90%;
        font-size: 12px;
        color: #ccc;
        transform: scale(0.75);
        cursor: pointer;
      }
    }
    &:hover {
      .del-item {
        display: inline-block;
      }
    }
  }
}
.add-icon {
  margin-top: 14px;
}
.doc-list {
  .doc-item {
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      padding: 10px;
      border-radius: 4px;
      background: #f4f1f4;
    }
  }
}
.button-list {
  margin-top: 20px;
  text-align: center;
}
</style>
